import React, { useState } from 'react';
import { Box, Button, constants, Typography as Text } from '@match/react-component-library';
import { LandingLegal } from './LandingLegal';
import { useContainerQuery, EmailAddressInput } from 'modules/components';
import { SubmitApplicationRequest } from 'types';

const { FONT_SIZE, SPACE } = constants;

interface LandingSubmissionViewProps {
  onEmailSubmit: (submitRequest: SubmitApplicationRequest) => void;
}

export const LandingSubmissionView = ({ onEmailSubmit }: LandingSubmissionViewProps) => {
  const [emailAddress, setEmailAddress] = useState<string>('');
  const { mobile } = useContainerQuery();

  const [emailAddressError, setEmailAddressError] = useState<boolean>(false);

  const emailAddressValid = emailAddress.length > 4 && !emailAddressError;

  const checkEmailAddress = (emailAddress: string) => {
    if (emailAddress === null || emailAddress === undefined)
      return setEmailAddressError(true);

    const emailPattern = new RegExp(
      /^[\w.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9.-]+$/i
    );
    
    return setEmailAddressError(!emailPattern.test(emailAddress));
  }

  const emailAddressOnChange = (emailAddress: string) => {
    setEmailAddressError(false);
    setEmailAddress(emailAddress);
  }

  const requestBody: SubmitApplicationRequest = {
    emailAddress: emailAddress,
    displayType: '',
    firstName: '',
    flowVersion: 1,
    lastName: '',
    pricePoint: 1,
    profile: {
      education: '',
      linkedIn: '',
      jobTitle: ''
    },
  };

  const inputBoxWrapperMarginBottom = mobile ? SPACE.LARGE : SPACE.LARGE;
  const labelBoxWrapperMarginBottom = mobile ? SPACE.MEDIUM : SPACE.MEDIUM;
  return (
    <Box center flexDirection="column">
      <Text fontSize={FONT_SIZE.XXLARGE}>COMING SOON.</Text>
      <Box 
        marginY={SPACE.MEDIUM}
        marginBottom={SPACE.SMALL}> 
        <Text fontSize={FONT_SIZE.MEDIUM}>A community of singles passionate about an active and healthy lifestyle.</Text>
      </Box>
      <Box 
        marginY={SPACE.SMALL}
        marginBottom={SPACE.LARGE}> 
        <Text fontSize={FONT_SIZE.MEDIUM}>Be first on the list.</Text>
      </Box>
      <EmailAddressInput 
        checkEmailAddress={checkEmailAddress}
        emailAddressOnChange={emailAddressOnChange}
        emailAddressError={emailAddressError}
        emailAddress={emailAddress}
        inputBoxWrapperMarginBottom={inputBoxWrapperMarginBottom}
        labelBoxWrapperMarginBottom={labelBoxWrapperMarginBottom}
        labelBoxWrapperTextAlign="left"
      />
      <LandingLegal />
      <Box marginBottom={SPACE.LARGE} width={300}>
        <Button
          onClick={() => onEmailSubmit(requestBody)}
          disabled={!emailAddressValid}
          size="large"
          fullWidth
          color="core1_100"
        >
          I'm in
        </Button>
      </Box>
    </Box>
  );
};
