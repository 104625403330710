import React from 'react';
import { useLocation } from 'react-router-dom';
import api from 'utils/api-services';

export default function LogPageCode() {
  const location = useLocation();

  React.useEffect(() => {
    const loggedPath = location.pathname.replace('/', '_');
    api.postPageCode({
      pagePath: `activehealthy${loggedPath === '_' ? '_landing' : loggedPath}_viewed`,
    });
  }, [location.pathname]);

  return null;
}
