import React from 'react';
import { Box, constants, Input, Typography } from '@match/react-component-library';

const { FONT_SIZE, FONT_WEIGHT, RADII, SEMANTIC_COLOR_NAME, SPACE } = constants;

interface EmailAddressInputProps {
  checkEmailAddress: (emailAddress: string) => void;
  emailAddressOnChange: (emailAddress: string) => void;
  emailAddressError: boolean;
  emailAddress: string;
  inputBoxWrapperMarginBottom: string;
  labelBoxWrapperMarginBottom: string;
  labelBoxWrapperTextAlign: any;
}

const EmailAddressInput = ({checkEmailAddress, emailAddressOnChange, emailAddressError, emailAddress, inputBoxWrapperMarginBottom, labelBoxWrapperMarginBottom, labelBoxWrapperTextAlign} : EmailAddressInputProps ) => {
  return (
    <Box>
      <Box 
        paddingLeft={SPACE.XSMALL}
        backgroundColor={SEMANTIC_COLOR_NAME.TRANSPARENT}
        marginBottom={labelBoxWrapperMarginBottom}
        textAlign={labelBoxWrapperTextAlign}
      >
        <Typography fontSize={FONT_SIZE.SMALL} fontWeight={FONT_WEIGHT.SEMIBOLD}>
        1. What is your email address?
        </Typography>
      </Box>
      <Box
        backgroundColor={SEMANTIC_COLOR_NAME.LIGHT}
        borderRadius={RADII.ROUND_CORNER_2X}
        marginBottom={inputBoxWrapperMarginBottom}
      >
      
      <Input
          onBlur={(e) => checkEmailAddress(e.target.value)}
          onChange={(e) => emailAddressOnChange(e.target.value)}
          placeholder="Ex: Linda@example.com"
          hasError={emailAddressError}
          value={emailAddress}
          width={250}
          autoComplete="email"
        />
      </Box>
    </Box>
  )
}

export default EmailAddressInput;