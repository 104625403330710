import React from 'react';
import styled from '@emotion/styled';
import { Box, constants, Typography as Text } from '@match/react-component-library';
import { useContainerQuery } from 'modules/components';

const { FONT_SIZE, SPACE } = constants;

const StyledAnchor = styled('a')({
  color: 'white',
  fontWeight: 'bold',
  textDecoration: 'underline',
  [':active']: { // eslint-disable-line
    color: 'white',
    textDecoration: 'underline',
  },
  [':visited']: { // eslint-disable-line
    color: 'white',
    textDecoration: 'underline',
  },
});

export const LandingLegal = () => {
  const { mobile } = useContainerQuery();
  return (
    <Box
      marginTop={mobile ? SPACE.LARGE : SPACE.NONE}
      marginBottom={SPACE.MEDIUM}
      maxWidth={400}
    >
      <Text fontSize={FONT_SIZE.XXXSMALL}>
      By signing up, I agree to receive emails from Match Group, LLC. I understand that I am free to withdraw consent at any time. By tapping “I’m In”, I agree to the {' '}
        <StyledAnchor href="https://www.match.com/registration/membagr.aspx?lid=4" target="_blank">
          {'Terms'}
        </StyledAnchor>
        . Learn how we process your data in our {' '}
        <StyledAnchor
          href="https://www.match.com/registration/privacystatement.aspx?lid=4"
          target="_blank"
        >
          Privacy Policy
        </StyledAnchor>
        {' '}and{' '}
         <StyledAnchor
          href="https://www.match.com/registration/cookiepolicy.aspx"
          target="_blank"
        >
          Cookie Policy
        </StyledAnchor>.
      </Text>
    </Box>
  );
};
