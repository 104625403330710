import React from 'react';
import styled from '@emotion/styled';

const StyledFooterLinks = styled('a')({
  color: 'white',
  [':active']: { // eslint-disable-line
    color: 'white',
  },
  [':visited']: { // eslint-disable-line
    color: 'white',
  },
});

export const LandingFooter = () => {
  return (
    <div>
      Copyright © 2022 Match Group LLC
      <div>
        <StyledFooterLinks
          href="https://www.match.com/registration/membagr.aspx?lid=4"
          target="_blank"
        >
          Terms of Use
        </StyledFooterLinks>
        &nbsp;|&nbsp;
        <StyledFooterLinks
          href="https://www.match.com/registration/privacystatement.aspx?lid=4"
          target="_blank"
        >
          Privacy Policy
        </StyledFooterLinks>
        &nbsp;|&nbsp;
        <StyledFooterLinks href="http://match.com/registration/cookiepolicy.aspx" target="_blank">
          Cookie Policy
        </StyledFooterLinks>
        &nbsp;|&nbsp;
        <StyledFooterLinks href="https://www.match.com/help/safetytips.aspx?lid=4" target="_blank">
          Safety Tips
        </StyledFooterLinks>
      </div>
    </div>
  );
};
