import React from 'react';
import { Box, constants, SelectNative, Typography } from '@match/react-component-library';
import { SCALE } from '../../constants';

const { FONT_SIZE, FONT_WEIGHT, RADII, SEMANTIC_COLOR_NAME, SPACE } = constants;

interface LinkedInInputProps {
  setLinkedIn: (linkedIn: string) => void;
  inputBoxWrapperMarginBottom: string;
  labelBoxWrapperTextAlign: any;
}

const LinkedInInput = ({setLinkedIn, inputBoxWrapperMarginBottom, labelBoxWrapperTextAlign} : LinkedInInputProps ) => {
  return (
    <Box
      backgroundColor={SEMANTIC_COLOR_NAME.TRANSPARENT}
      borderRadius={RADII.ROUND_CORNER_2X}
      marginBottom={inputBoxWrapperMarginBottom}
      width={250}
      textAlign={labelBoxWrapperTextAlign}
    >
      <Box as="legend" paddingBottom={SPACE.SMEDIUM} paddingLeft={SPACE.XSMALL}>
        <Typography fontSize={FONT_SIZE.SMALL} fontWeight={FONT_WEIGHT.SEMIBOLD}>
          4. How likely are you to share your LinkedIn profile on your dating profile?
        </Typography>
      </Box>
      <Box
        backgroundColor={SEMANTIC_COLOR_NAME.LIGHT}
        borderRadius={RADII.ROUND_CORNER_1X}
        border='none'
        borderColor='transparent'
      >
        <SelectNative 
          id="linkedin" 
          name="linkedin" 
          onChange={(e) => setLinkedIn(e.currentTarget.value)}
        >
          <option value={SCALE.MOST_LIKELY}>{SCALE.MOST_LIKELY}</option>
          <option value={SCALE.NOT_SURE}>{SCALE.NOT_SURE}</option>
          <option value={SCALE.NOT_LIKELY}>{SCALE.NOT_LIKELY}</option>
          <option value={SCALE.DO_NOT_HAVE}>{SCALE.DO_NOT_HAVE}</option>
          <option value={SCALE.NO_ANSWER}>{SCALE.NO_ANSWER}</option>
        </SelectNative>
      </Box>
    </Box>
  )
}

export default LinkedInInput;