import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { ThemeProvider, GlobalFontFace, GlobalCssReset } from '@match/react-component-library';
import { ContainerQueryProvider } from 'modules/components';
import LogPageCode from 'modules/components/LogPageCode';
import { Stir } from 'modules/pages';
import { themeBuilder } from 'utils/theme-builder';

const App: React.FunctionComponent = () => {
  const mavenTheme = themeBuilder();

  return (
    <ThemeProvider theme={mavenTheme}>
      <ContainerQueryProvider>
        <GlobalFontFace />
        <GlobalCssReset borderBox margins padding lists images anchors />
        <BrowserRouter>
          <LogPageCode />
          <Switch>
            <Route component={Stir} />
          </Switch>
        </BrowserRouter>
      </ContainerQueryProvider>
    </ThemeProvider>
  );
};

export default App;
