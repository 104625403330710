import { themeFantasticLlama } from '@match/react-component-library';
import wulkanDisplaySemiBold from './fonts/wulkandisplay-semibold.woff';
import wulkanDisplayBold from './fonts/wulkandisplay-bold.woff';
import workSansBold from './fonts/worksans-bold.woff';
import workSansRegular from './fonts/worksans-regular.woff';

export const themeBuilder = () => {
  const mavenTheme = Object.assign({}, themeFantasticLlama);
  mavenTheme.weights.semibold = 600;
  mavenTheme.lineHeights.xxxlarge = '48px';
  // mavenTheme.palette.ctaPrimary = '#2F2D2B';
  // mavenTheme.palette.functional1Base = '#F6F1EE';
  // mavenTheme.palette.functional1Dark = '#ECE7E4';
  mavenTheme.space.large = 24;
  mavenTheme.space.xlarge = 32;
  mavenTheme.space.xxlarge = 40;
  mavenTheme.space.xxxlarge = 80;
  mavenTheme.fontVariants = () => [
    {
      family: 'Wulkan display',
      url: wulkanDisplaySemiBold,
      weight: 600,
      format: 'woff',
    },
    {
      family: 'Wulkan display',
      url: wulkanDisplayBold,
      weight: 700,
      format: 'woff',
    },
    {
      family: 'Work Sans',
      url: workSansRegular,
      weight: 'normal',
      format: 'woff',
    },
    {
      family: 'Work Sans',
      url: workSansBold,
      weight: 700,
      format: 'woff',
    },
  ];
  mavenTheme.font = {
    monospace: "'Wulkan display', Helvetica, Arial, sans-serif",
    body: "'Work Sans', Helvetica, Arial, sans-serif",
    heading: "'Wulkan display', Helvetica, Arial, sans-serif",
  };
  // mavenTheme.components.button.sizes.xlarge.height = '60px';
  // mavenTheme.components.button = {
  //   ...mavenTheme.components.button,
  //   defaults: ({ primary }) => ({
  //     cursor: 'pointer',
  //     border: 'none',
  //     position: 'relative',
  //     background: primary,
  //     fontFamily: 'inherit',
  //     fontWeight: 'bold',
  // color: mavenTheme.palette.light,
  // '&:active, :focus': {
  //   backgroundColor: '#949595',
  // },
  //   }),
  //   disabled: () => ({
  //     cursor: 'auto',
  //     position: 'relative',
  //     backgroundColor: '#949595',
  //   }),
  // };
  return mavenTheme;
};
