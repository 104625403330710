import React from 'react';
import { Box, constants, Typography as Text } from '@match/react-component-library';

const { FONT_SIZE, FONT_WEIGHT, SPACE } = constants;

export const ConfirmationView = () => {
  return (
    <div>
      <Text fontSize={FONT_SIZE.XXXXLARGE} fontWeight={FONT_WEIGHT.BOLD}>
        You&apos;re in!
      </Text>
      <Box marginTop={SPACE.LARGE}>
        <Text fontSize={FONT_SIZE.XXLARGE}>You&apos;ll hear from us soon!</Text>
      </Box>
    </div>
  );
};
