import React from 'react';
import { Box, constants, Input, Typography} from '@match/react-component-library';

const {FONT_SIZE, FONT_WEIGHT, RADII, SEMANTIC_COLOR_NAME, SPACE } = constants;

interface ProfessionInputProps {
  professionOnChange: (profession: string) => void;
  profession: string;
  inputBoxWrapperMarginBottom: string;
  labelBoxWrapperMarginBottom: string;
  labelBoxWrapperTextAlign: any;
}

const ProfessionInput = ({professionOnChange, profession, inputBoxWrapperMarginBottom, labelBoxWrapperMarginBottom, labelBoxWrapperTextAlign} : ProfessionInputProps ) => {
  return (
    <Box>
      <Box 
        paddingLeft={SPACE.XSMALL}
        backgroundColor={SEMANTIC_COLOR_NAME.TRANSPARENT}
        marginBottom={labelBoxWrapperMarginBottom}
        textAlign={labelBoxWrapperTextAlign}
      >
        <Typography fontSize={FONT_SIZE.SMALL} fontWeight={FONT_WEIGHT.SEMIBOLD}>
        3. What is your profession?
        </Typography>
      </Box>
      <Box
        backgroundColor={SEMANTIC_COLOR_NAME.LIGHT}
        borderRadius={RADII.ROUND_CORNER_2X}
        marginBottom={inputBoxWrapperMarginBottom}
      >
        
        <Input
          onChange={(e) => professionOnChange(e.target.value)}
          placeholder="Ex: Pharmacist, Manager"
          value={profession}
          width={250}
        />
      </Box>
    </Box>
  )
}

export default ProfessionInput;