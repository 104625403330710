import React from 'react';
import styled from '@emotion/styled';
import { Box, constants } from '@match/react-component-library';
import apiServices from 'utils/api-services';
import { LandingSubmissionView } from './LandingSubmissionView';
import { LandingFooter } from './LandingFooter';
import { ConfirmationView } from './ConfirmationView';
import { useContainerQuery } from 'modules/components';
import { SubmitApplicationRequest } from 'types';

const { SEMANTIC_COLOR_NAME, SPACE } = constants;

interface StyledLandingWrapperBoxProps {
  isMobile: boolean;
}

const StyledLandingWrapperBox = styled(Box)(({ isMobile }: StyledLandingWrapperBoxProps) => ({
  background: isMobile ? 'url("/images/beach.jpg")' : 'url("/images/landingBackground.jpg")',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}));

export const Landing = () => {
  const [confirmationView, setConfirmationView] = React.useState<boolean>(false);
  const { mobile } = useContainerQuery();

  const handleEmailSubmit = (submitRequest: SubmitApplicationRequest) =>
  {
    apiServices
      .createUser(submitRequest)
      .then(() => {
        setConfirmationView(true);
        return;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const contentHeight = mobile ? '90%' : 600;

  return (
    <StyledLandingWrapperBox height="100vh" width="100%" isMobile={false} center>
      <Box
        alignItems="center"
        color={SEMANTIC_COLOR_NAME.LIGHT}
        display="flex"
        flexDirection="column"
        height={contentHeight}
        justifyContent="space-between"
        overflow="auto"
        paddingLeft={SPACE.LARGE}
        paddingRight={SPACE.LARGE}
        position="absolute"
        textAlign="center"
        width="100%"
      >
        {confirmationView ? (
          <ConfirmationView />
        ) : (
          <LandingSubmissionView onEmailSubmit={handleEmailSubmit} />
        )}
        <LandingFooter />
      </Box>
    </StyledLandingWrapperBox>
  );
};
