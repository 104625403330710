import React from 'react';
import { ContainerQuery } from 'react-container-query';

const responsiveQuery = {
  mobile: {
    maxWidth: 600,
  },
  tall: {
    minHeight: 725,
  },
  short: {
    maxHeight: 600,
  },
};

export interface ContainerQueryProps {
  device?: {
    mobile: boolean;
    short: boolean;
    tall: boolean;
  };
}

export const ContainerQueryContext = React.createContext({
  mobile: false,
  short: false,
  tall: false,
});

export default class ContainerQueryProvider extends React.Component {
  render() {
    return (
      <ContainerQuery query={responsiveQuery}>
        {(params) => (
          <ContainerQueryContext.Provider value={params as any}>
            {this.props.children}
          </ContainerQueryContext.Provider>
        )}
      </ContainerQuery>
    );
  }
}
