import axios, { AxiosResponse } from 'axios';
import { SubmitApplicationRequest } from 'types';

const API_URL = `${process.env.PUBLIC_URL}/api`;

const axiosInstance = axios.create({
  baseURL: API_URL,
});

const MAX_REQUESTS_COUNT = 1;
const INTERVAL_MS = 100;
let PENDING_REQUESTS = 0;

// Request middleware
axiosInstance.interceptors.request.use(
  (config) => {
    // Add something to each request
    return new Promise((resolve, _reject) => {
      const interval = setInterval(() => {
        if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
          PENDING_REQUESTS++;
          clearInterval(interval);
          resolve(config);
        }
      }, INTERVAL_MS);
    });
  },
  (error) => {
    // Do something for each request error.
    return Promise.reject(error);
  }
);

// Targeting criteria response middleware
axiosInstance.interceptors.response.use(
  (response) => {
    // Do something after each successful response
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
    return Promise.resolve(response);
  },
  (error) => {
    // Do something for each api error.
    // e.g. redirect 401 responses to login page
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
    return Promise.reject(error);
  }
);

const request = (config = {}) => axiosInstance(config);

export default {
  getPrice: () => {
    const qs = window.location.search.toLowerCase();
    const queryParams = new URLSearchParams(qs);
    const emailId = queryParams.get('crmid') || queryParams.get('emailid');
    if (emailId) {
      const version = queryParams.get('version');
      let price = 199;
      if (version) {
        switch (version) {
          case '99':
            price = 99;
            break;
          case '149':
            price = 149;
            break;
          case '199':
            price = 199;
            break;
        }
      }
      return Promise.resolve(price);
    } else {
      return request({
        method: 'GET',
        url: '/price',
      })
        .then((res: AxiosResponse<string>) => {
          const price = parseInt(res.data) || 199;
          return price;
        })
        .catch(() => {
          return 199;
        });
    }
  },
  postPageCode: ({ pagePath }: { pagePath: string }) => {
    const qs = window.location.search.toLowerCase();
    const queryParams = new URLSearchParams(qs);
    const emailId = queryParams.get('crmid') || queryParams.get('emailid');
    const trackingId = queryParams.get('trackingid');
    const bannerId = queryParams.get('bannerid');
    return request({
      params: {
        emailid: emailId,
        trackingid: trackingId,
        bannerid: bannerId,
      },
      method: 'POST',
      data: {
        Path: pagePath,
      },
      url: '/trackPageCode',
    })
      .then(() => {
        return;
      })
      .catch((err: Error) => {
        console.log(err);
        return undefined;
      });
  },
  createUser: (data: SubmitApplicationRequest) => {
    return request({
      url: '/user',
      method: 'POST',
      data,
    });
  },
};
