import React from 'react';
import { Box, constants, SelectNative, Typography } from '@match/react-component-library';
import { EDUCATION } from '../../constants';

const { FONT_SIZE, FONT_WEIGHT, RADII, SEMANTIC_COLOR_NAME, SPACE  } = constants;

interface EducationInputProps {
  setEducation: (education: string) => void;
  inputBoxWrapperMarginBottom: string;
  labelBoxWrapperTextAlign: any;
}

const EducationInput = ({setEducation, inputBoxWrapperMarginBottom, labelBoxWrapperTextAlign} : EducationInputProps ) => {
  return (
    <Box
      backgroundColor={SEMANTIC_COLOR_NAME.TRANSPARENT}
      borderRadius={RADII.ROUND_CORNER_2X}
      marginBottom={inputBoxWrapperMarginBottom}
      textAlign={labelBoxWrapperTextAlign}
    >
      <Box as="legend" paddingBottom={SPACE.SMEDIUM} paddingLeft={SPACE.XSMALL}>
        <Typography fontSize={FONT_SIZE.SMALL} fontWeight={FONT_WEIGHT.SEMIBOLD}>
        2. What is your education level?
        </Typography>
      </Box>
      <Box
        backgroundColor={SEMANTIC_COLOR_NAME.LIGHT}
        borderRadius={RADII.ROUND_CORNER_1X}
        border='none'
      >
        <SelectNative id="education" name="education"  onChange={(e) => setEducation(e.currentTarget.value)}>
          <option value={EDUCATION.HIGH_SCHOOL}>{EDUCATION.HIGH_SCHOOL}</option>
          <option value={EDUCATION.ASSOCIATES}>{EDUCATION.ASSOCIATES}</option>
          <option value={EDUCATION.BACHELORS}>{EDUCATION.BACHELORS}</option>
          <option value={EDUCATION.MASTERS}>{EDUCATION.MASTERS}</option>
          <option value={EDUCATION.PHD}>{EDUCATION.PHD}</option>
          <option value={EDUCATION.NO_ANSWER}>{EDUCATION.NO_ANSWER}</option>
        </SelectNative>
      </Box>
    </Box>
  )
}

export default EducationInput;