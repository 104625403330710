import React from 'react';
import styled from '@emotion/styled';

interface ScreenProps {
  backgroundColor?: string;
  children: React.ReactNode | React.ReactNode[];
  className?: string;
  fullHeight?: boolean;
  zIndex?: number;
}

interface StyledProps extends ScreenProps {
  theme?: any;
}

const StyledSection = styled('section')(
  ({ theme, backgroundColor, fullHeight, zIndex }: StyledProps) => {
    const background = (backgroundColor && theme.palette[backgroundColor]) || null;
    return {
      boxSizing: 'border-box',
      height: fullHeight ? '100vh' : undefined,
      zIndex: zIndex,
      background,
    };
  }
);

function Screen(props: ScreenProps, ref: React.Ref<HTMLDivElement>) {
  return (
    <StyledSection
      ref={ref}
      backgroundColor={props.backgroundColor}
      fullHeight={props.fullHeight}
      className={props.className}
      zIndex={props.zIndex}
    >
      {props.children}
    </StyledSection>
  );
}

export default React.forwardRef(Screen);
